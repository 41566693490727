import type { 
  Week,
} from '~/types/diary'
import { 
  DiaryChartTypes,
  WeekFaza,
} from '~/types/diary'

export function getRandomString(length: number): string {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function isValidUrl(str: string, type: string): boolean {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[@-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!pattern.test(str)) {
    return false;
  } else {
    if(str.indexOf(type) < 0){
      return false;
    }else{
      return true;
    }

  }
}



import { ColorPaletteRandom } from '@/types/other/Other.types';


export const getColorByNumber = (num: string | number | null | undefined): string => {
  const index = parseInt(String(num ?? 0).slice(-2), 10);
  return ColorPaletteRandom[index] || ColorPaletteRandom[0];
};




import { AllowedDomainLinks } from '@/types/other/Other.types';

export const formatAllowedLinks = (text: string, allowAllDomains: boolean = false) => {
 
  let domainPattern = AllowedDomainLinks.join('|').replace(/\./g, '\\.');

  if (allowAllDomains) {
    domainPattern = '[^\\s]+'; 
  }

  const pattern = new RegExp(`\\b(?:https?:\\/\\/)?(?:${domainPattern})\\S*`, 'gi');

  return text.replace(pattern, (url) => {

    if (!/^https?:\/\//.test(url)) {
      url = 'http://' + url;
    }

    const urlObj = new URL(url);
    const host = urlObj.host;

    if (allowAllDomains || AllowedDomainLinks.includes(host)) {
      const displayUrl = host + urlObj.pathname; 
      return `<a href="${url}">${displayUrl}</a>`;
    }

    return url; 
  });
}
  

export const formatUserMentions = (text: string) => {
  if(text && text.length){
    return text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/grower/$1">@$1</a>');
  }else{
    return '';
  }
}

export const formatCommonText = (text: string) => {
  text = formatAllowedLinks(text);
  text = formatUserMentions(text);
  return text;
}

export const formatCroppedText = (text: string, limit: number = 200) => {
  if(text && text.length > limit){
    return text.substring(0, limit) + '...';
  }else{
    return text;
  }
}


export const shortCounter = (num: number) => {
  if(num > 999999)
    return  (Math.floor(num/1000000)) + 'M+'
  else if(num > 999)
    return  (Math.floor(num/1000)) + 'K+'
  else if(num > 300)
    return (Math.floor(num/100)) + '00+'
  return num;
}



export function sliceQuestionAnswerTextRegex(html: string): { title: string; text: string }[] {
  const pattern = /<h2>(.*?)<\/h2>([\s\S]*?)(?=<h2>|$)/g;
  const results: { title: string; text: string }[] = [];
  
  let match;
  while ((match = pattern.exec(html)) !== null) {
    const title = match[1].trim();
    const text = match[2].trim();
    if(title.length && text.length) {
      results.push({ title, text });
    }
  }
  
  return results;
}









// for summary charts

import { viewValue } from '~/types/other'

export function getChartWeekData(
  weeks: Week[],
  type: string,
  max: number,
  unit: string,
  measure: string
): Array<{ x: number; y: number | null }> {
  const weekValues = new Map<number, number>()

  weeks.forEach((week) => {
    const val = week.props?.[type]
    if (val !== undefined && val !== null && week.faza !== WeekFaza.Harvest) {

      let converted = viewValue(unit, measure, val)
      weekValues.set(Number(week.days), converted)
      // weekValues.set(Number(week.days), converted)
    }
  })

  const result: Array<{ x: number; y: number | null }> = []
  for (let w = 0; w <= max; w++) {
    result.push({
      x: w,
      y: weekValues.has(w) ? weekValues.get(w)! : null
    })
  }

  return result
}


export function getChartWeekMaxWeeks(weeks: Week[]): number {
  let maxDays = 0

  weeks.forEach((week) => {
    const d = week.days
    if (typeof d === 'number' && d > maxDays && week.faza !== WeekFaza.Harvest) {
      maxDays = d
    }
  })

  return maxDays
}

export function getFirstActiveSeries(weeks: Week[]): string {
  for (const s of DiaryChartTypes) {
    for (const week of weeks) {
      if (week.props?.[s.prop] !== undefined && week.props?.[s.prop] !== null) {
        return s.prop
      }
    }
  }
  return ''
}

// end for summary charts





export function markdownToHtml(markdownText: string): string {

  if (!markdownText) {
    return '';
  }

  let html = markdownText;

  // Конвертация заголовков (h1-h6)
  html = html.replace(/^######\s?(.*)$/gm, '<h6>$1</h6>');
  html = html.replace(/^#####\s?(.*)$/gm, '<h5>$1</h5>');
  html = html.replace(/^####\s?(.*)$/gm, '<h4>$1</h4>');
  html = html.replace(/^###\s?(.*)$/gm, '<h3>$1</h3>');
  html = html.replace(/^##\s?(.*)$/gm, '<h2>$1</h2>');
  html = html.replace(/^#\s?(.*)$/gm, '<h1>$1</h1>');

  // Конвертация жирного текста
  html = html.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

  // Конвертация курсива
  html = html.replace(/\*(.+?)\*/g, '<em>$1</em>');

  // Конвертация inline-кода
  html = html.replace(/`(.+?)`/g, '<code>$1</code>');

  // Конвертация ссылок
  html = html.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2">$1</a>');

  // Конвертация нумерованного списка
  html = html.replace(/^(\d+)\.\s(.+)$/gm, '<li><strong>$1.</strong> $2</li>');

  // Оборачивание списка в тег <ol>
  if (/<li>/.test(html)) {
    html = html.replace(/((<li>.*?<\/li>\s*)+)/gs, '<ol>$1</ol>');
  }

  // Конвертация абзацев
  html = html.replace(/\n{2,}/g, '</p><p>');
  html = '<p>' + html + '</p>';

  // Очистка лишних переносов
  html = html.replace(/<br>\s*<br>/g, '<br>');

  return html;
}