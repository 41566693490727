// converterHelpers.ts

/**
 * Rounds a number to the specified number of decimal places.
 */
export function mathRound(vl: number, dec?: number): number {
  const decimals = Math.pow(10, dec ?? 2);
  return Math.round(vl * decimals) / decimals;
}

/**
 * Universal multiplication function with rounding.
 */
export function cnv(mn: number, vl: number, dec?: number): number {
  return mathRound(vl * mn, dec);
}

/**
 * Converts Fahrenheit to Celsius.
 */
export function f_c(vl: number, dec?: number): number {
  return mathRound((5 / 9) * (vl - 32), dec);
}

/**
 * Converts Celsius to Fahrenheit.
 */
export function c_f(vl: number, dec?: number): number {
  return mathRound((9 / 5) * vl + 32, dec);
}

/**
 * Converts centimeters to inches.
 */
export function cm_inch(vl: number, dec?: number): number {
  return cnv(0.393701, vl, dec);
}

/**
 * Converts inches to centimeters.
 */
export function inch_cm(vl: number, dec?: number): number {
  return cnv(2.54, vl, dec);
}

/**
 * Converts grams to ounces.
 */
export function gr_oz(vl: number, dec?: number): number {
  // Для очень маленьких значений даём чуть больше точности
  const precision = vl < 0.0099 ? 3 : dec ?? 2;
  return cnv(0.035274, vl, precision);
}

/**
 * Converts ounces to grams.
 */
export function oz_gr(vl: number, dec?: number): number {
  return cnv(28.3495, vl, dec);
}

/**
 * Converts liters to gallons.
 */
export function l_gal(vl: number, dec?: number): number {
  return cnv(0.264172, vl, dec);
}

/**
 * Converts gallons to liters.
 */
export function gal_l(vl: number, dec?: number): number {
  return cnv(3.78541, vl, dec);
}

/**
 * Converts ppm to EC.
 */
export function ppm_ec(vl: number, dec?: number): number {
  return mathRound((vl * 2) / 1000, dec);
}

/**
 * Converts EC to ppm.
 */
export function ec_ppm(vl: number, dec?: number): number {
  return mathRound((vl * 1000) / 2, dec);
}

/**
 * Converts milliliters per liter (ml/l) to milliliters per gallon (ml/gal).
 */
export function mll_mlgal(vl: number, dec?: number): number {
  return cnv(3.78541, vl, dec);
}

/**
 * Converts milliliters per liter (ml/l) to teaspoons per gallon (tsp/gal).
 */
export function mll_tspgal(vl: number, dec?: number): number {
  return cnv(0.768, vl, dec);
}

/**
 * Converts milliliters per gallon (ml/gal) to milliliters per liter (ml/l).
 */
export function mlgal_mll(vl: number, dec?: number): number {
  return cnv(0.264172, vl, dec);
}

/**
 * Converts teaspoons per gallon (tsp/gal) to milliliters per liter (ml/l).
 */
export function tspgal_mll(vl: number, dec?: number): number {
  return cnv(1.30208, vl, dec);
}

/**
 * Converts a value from the "library" units to the local unit of measurement.
 * For example, if everything is stored in the database in metric, but the user needs to display it in "imperial".
 */
export function legitimed(unit: string, current: string, vl: number, dec?: number): number | null {
  if (vl == null) return null;
  dec = dec ?? 2;

  switch (unit) {
    case 'temperature':
      return current === 'f' ? vl : c_f(vl, 0);

    case 'square':
    case 'length':
    case 'height':
      return current === 'metric' ? vl : inch_cm(vl, dec);

    case 'weight':
      return current === 'metric' ? vl : oz_gr(vl, dec);

    case 'volume':
      return current === 'l' ? vl : gal_l(vl, dec);

    case 'tds':
      return current === 'ppm' ? vl : ec_ppm(vl, dec);

    case 'nutrient':
      if (current === 'mll') return vl;
      if (current === 'tspgal') return tspgal_mll(vl, 2);
      return mlgal_mll(vl, dec);

    default:
      return vl;
  }
}

/**
 * Returns null if the value is null.
 */
export function universal(unit: string, target: string, vl: number): number | null {
  if (vl == null) return null;

  switch (unit) {
    case 'temperature':
      return target === 'f' ? vl : f_c(vl, 0);

    case 'square':
    case 'length':
    case 'height':
      return target === 'metric' ? vl : cm_inch(vl, 2);

    case 'weight':
      return target === 'metric' ? vl : gr_oz(vl, 2);

    case 'volume':
      return target === 'l' ? vl : l_gal(vl, 2);

    case 'tds':
      return target === 'ppm' ? mathRound(vl, 2) : ppm_ec(vl, 2);

    case 'nutrient':
      if (target === 'mll') return vl;
      if (target === 'tspgal') return mll_tspgal(vl, 2);
      return mll_mlgal(vl, 2);

    default:
      return vl;
  }
}

/**
 * Round value depending on the unit of measurement and its variations.
 */
export function roundValue(unit: string, measure: string, vl: number): number {
  switch (unit) {
    case 'temperature':
      return mathRound(vl);

    case 'square':
    case 'length':
    case 'height':
      return measure === 'metric' ? mathRound(vl) : mathRound(vl, 2);

    case 'weight':
      return measure === 'metric' ? mathRound(vl) : mathRound(vl, 2);

    case 'volume':
      return measure === 'l' ? mathRound(vl) : mathRound(vl, 2);

    case 'tds':
      return mathRound(vl, 2);

    case 'nutrient':
      if (measure === 'mll') return mathRound(vl);
      if (measure === 'tspgal') return mathRound(vl, 2);
      return mathRound(vl, 2);

    default:
      return mathRound(vl);
  }
}

/**
 * Needed for displaying values (optionally – with rounding).
 */
export function viewValue(unit: string, target: string, vl: number, decParam?: number): number | null {
  if (vl == null) return null;

  const decDigits = decParam ?? 2;

  switch (unit) {
    case 'temperature':
      return target === 'f' ? mathRound(vl, decDigits) : f_c(vl, 0);

    case 'square':
    case 'length':
    case 'height':
      return target === 'metric' ? mathRound(vl, decDigits) : cm_inch(vl, decDigits);

    case 'weight':
      return target === 'metric' ? mathRound(vl, decDigits) : gr_oz(vl, decDigits);

    case 'volume':
      return target === 'l' ? mathRound(vl, 0) : l_gal(vl, 0);

    case 'tds':
      return target === 'ppm' ? vl : ppm_ec(vl, decDigits);

    case 'nutrient':
      if (target === 'mll') return mathRound(vl, decDigits);
      if (target === 'tspgal') return mll_tspgal(vl, decDigits);
      return mll_mlgal(vl, decDigits);

    default:
      return mathRound(vl, decDigits);
  }
}